import Rails from "@rails/ujs"

function get_row(element) {
  return element.closest(".order_card")
}

export function change_count(object) {
  let input = object.parentNode.querySelector('input[type=number]')
  const row = get_row(object)
  const count = object.value
  Rails.ajax({
    type: "PATCH",
    dataType: "json",
    url: "/products/" + row.dataset.productId + "/change_count",
    data: new URLSearchParams({
      count: count
    }).toString(),
    success: function(response) {
      let sum_price = row.querySelector('.sum_price');
      let order_price_element = document.getElementById("orders_price");
      order_price_element.innerText = (response["sum"]).toFixed(2);
      sum_price.innerText = (response["product_sum"]).toFixed(2);
    }
  })
}

export function remove_from_basket(object) {
  const row = get_row(object);
  Rails.ajax({
    type: "DELETE",
    dataType: "json",
    url: "/products/" + row.dataset.productId + "/remove_from_basket",
    success: function(response) {
      const basketCount = document.getElementById('basket_count');
      basketCount.innerText = response.count;

      let sum_price = row.querySelector('.sum_price')
      let order_price_element = document.getElementById("orders_price")
      order_price_element.innerText = (parseFloat(order_price_element.textContent) - parseFloat(sum_price.textContent)).toFixed(2)

      row.parentNode.parentNode.removeChild(row.parentNode)

    }
  })
}
