// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "@hotwired/turbo-rails"
import "trix"
import "@rails/actiontext"

Rails.start()
ActiveStorage.start()
import toastr from "toastr";

toastr.options = {
    "closeButton": false,
    "debug": false,
    "positionClass": "toast-top-center",
    "onclick": null,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
}
window.toastr = toastr

import {Collapse} from 'bootstrap';

window.Collapse = Collapse;
import "stylesheets/application"

import {
    category_item,
    page,
    sort,
    add_to_basket
} from "./сatalog";

window.category_item = category_item;
window.page = page;
window.sort = sort;
window.add_to_basket = add_to_basket;

import {
    change_count,
    remove_from_basket
} from "./order";

window.change_count = change_count;
window.remove_from_basket = remove_from_basket;


// document.addEventListener('DOMContentLoaded', function() {
document.addEventListener("turbo:load", function () {
    let elements = document.querySelectorAll(".g-recaptcha");
    if (elements) {
        elements.forEach(function (element) {
            while (element.firstChild) {
                element.removeChild(element.firstChild);
            }
        });
    }

    if (document.getElementById("secondary-slider")) {
        var secondarySlider = new Splide('#secondary-slider', {
            fixedWidth: 100,
            height: 60,
            gap: 10,
            cover: false,
            isNavigation: true,
            focus: 'center',
            pagination: false,
            breakpoints: {
                '600': {
                    fixedWidth: 66,
                    height: 40,
                }
            },
        }).mount();
    }
    if (document.getElementById("image-slider")) {
        var primarySlider = new Splide('#image-slider', {
            type: 'fade',
            heightRatio: .8,
            pagination: false,
            arrows: false,
            cover: true,
        }); // do not call mount() here.

        primarySlider.sync(secondarySlider).mount();
    }
    if (document.getElementById("header_gallery")) {
        new Splide('#header_gallery', {
            type: 'fade',
            rewind: true,
            arrows: false,
        }).mount();
    }
});
// });