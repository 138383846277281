import Rails from "@rails/ujs"
import {
  Tooltip,
  Toast,
  Popover
} from 'bootstrap';

export function category_item(item) {
  Rails.ajax({
    type: "GET",
    url: "/categories/" + item.dataset.category_id,
    success: function(response) {
      let ul = item.querySelector("ul")
      ul.innerHTML = response.html;
      item.onclick = null;
    }
  })
  event.preventDefault();
}

export function page(object) {
  let sort = "name"
  if (document.getElementById("sort")) {
    sort = document.getElementById("sort").value
  }
  const data = {
    sort: sort,
    page: parseInt(object.dataset.page),
  }
  Rails.ajax({
    type: "GET",
    url: window.location.href,
    dataType: "json",
    data: new URLSearchParams(data).toString(),
    success: function(data) {
      document.getElementById("products").innerHTML = data.html
      document.getElementById("pagination").innerHTML = data.pagination_html
      window.scroll({
        top: document.getElementById("products").offsetTop,
        left: 0,
        behavior: 'smooth'
      });
    },
    error: function(repsonse) {}
  })
}

export function sort(object) {
  const data = {
    sort: document.getElementById("sort").value
  }
  Rails.ajax({
    type: "GET",
    url: window.location.href,
    dataType: "json",
    data: new URLSearchParams(data).toString(),
    success: function(data) {
      document.getElementById("products").innerHTML = data.html
      document.getElementById("pagination").innerHTML = data.pagination_html
      window.scroll({
        top: document.getElementById("products").offsetTop,
        left: 0,
        behavior: 'smooth'
      });
    },
    error: function(repsonse) {}
  })
}

export function add_to_basket(object) {
  var tooltip = new Tooltip(object, {
    animated: 'fade',
    placement: 'bottom',
    trigger: 'show',
    title: "Добавлено в корзину"
  });
  tooltip.show()
  Rails.ajax({
    type: "POST",
    dataType: "json",
    url: "/products/" + object.dataset.product_id + "/add_to_basket",
    success: function(response) {
      const basketCount = document.getElementById('basket_count')
      basketCount.innerText = response.count
    }
  })
}
